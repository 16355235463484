import React from 'react'
var throttle = require('lodash.throttle');
const StackBlur = require('stackblur-canvas');

const max = 1200;

function roundRect(ctx, x, y, width, height, radius, fill, stroke) {
  if (typeof stroke === 'undefined') {
    stroke = true;
  }
  if (typeof radius === 'undefined') {
    radius = 0;
  }
  if (typeof radius === 'number') {
    radius = {tl: radius, tr: radius, br: radius, bl: radius};
  } else {
    var defaultRadius = {tl: 0, tr: 0, br: 0, bl: 0};
    for (var side in defaultRadius) {
      radius[side] = radius[side] || defaultRadius[side];
    }
  }
  ctx.beginPath();
  ctx.moveTo(x + radius.tl, y);
  ctx.lineTo(x + width - radius.tr, y);
  ctx.quadraticCurveTo(x + width, y, x + width, y + radius.tr);
  ctx.lineTo(x + width, y + height - radius.br);
  ctx.quadraticCurveTo(x + width, y + height, x + width - radius.br, y + height);
  ctx.lineTo(x + radius.bl, y + height);
  ctx.quadraticCurveTo(x, y + height, x, y + height - radius.bl);
  ctx.lineTo(x, y + radius.tl);
  ctx.quadraticCurveTo(x, y, x + radius.tl, y);
  ctx.closePath();
  if (fill) {
    ctx.fill();
  }
  if (stroke) {
    ctx.stroke();
  }
}

class MyCanvas extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.ref = React.createRef();
    this.precomposed = [];
    this.throttled = throttle(this.buildCanvasNew, 33);
  }

  async buildCanvasNew() {
    let video = this.props.img.current;
    let canvas = this.ref.current;
    if (video && canvas) {
      this.props.pullback(canvas);
      let width = video.videoWidth;
      let height = video.videoHeight;
      if (width > max || height > max) {
        if (width > height) {
          height = Math.round((height / width) * max);
          width = max;
        } else {
          width = Math.round((width / height) * max);
          height = max;
        }
      }
      canvas.width = width;
      canvas.height = height;
      let ctx = canvas.getContext('2d');
      // ctx.clearRect(0, 0, canvas.width, canvas.height);
      ctx.drawImage(video,0,0,width,height);

      ctx.fillStyle = "#000000";
      ctx.globalAlpha = 1.0;
      ctx.filter = "none";
      ctx.imageSmoothingEnabled = true;

      let blurs = this.props.blurs;

      console.log();

      for (let blur of blurs) {
        if (video.currentTime >= blur.start && video.currentTime <= blur.end) {
          let ratio = ((video.currentTime - blur.start) / (blur.end - blur.start));
          let tlx = (blur.stlx + (ratio * (blur.ftlx - blur.stlx)));
          let tly = (blur.stly + (ratio * (blur.ftly - blur.stly)));
          let brx = (blur.sbrx + (ratio * (blur.fbrx - blur.sbrx)));
          let bry = (blur.sbry + (ratio * (blur.fbry - blur.sbry)));

          if (blur.type === 'text') {
            ctx.fillStyle = blur.color;
            ctx.globalAlpha = blur.level;
            let r = Math.min((brx - tlx), (bry - tly)) * blur.radius * 0.5;
            roundRect(ctx, tlx, tly, (brx - tlx), (bry - tly), r, "black", "black");
            let cw = Math.round(brx - tlx);
            cw = Math.max(cw, cw - 10);
            let fontsize = Math.min(30, 1.5 * cw / blur.text.length);
            ctx.font = fontsize + "px Courier";
            ctx.textAlign = "center";
            ctx.textBaseline = "middle";
            ctx.globalAlpha = 1.0;
            ctx.fillStyle = "#FFFFFF";
            ctx.fillText(blur.text, Math.round((tlx + brx) / 2), Math.round((tly + bry) / 2), cw);
            ctx.fillStyle = "#000000";
          } else if (blur.type === 'blur') {
            tlx = Math.round(tlx);
            tly = Math.round(tly);
            brx = Math.round(brx);
            bry = Math.round(bry);
            if ((blur.brx - tlx) > 0 && (bry - tly) > 0) {
              StackBlur.canvasRGB(canvas, tlx, tly, (brx - tlx), (bry - tly), Math.round((canvas.width/8) * blur.level));
            }
          }
        }
      }
    }
    this.throttled();
  }

  componentDidMount() {
    this.throttled();
  }

  componentDidUpdate() {
    this.throttled();
  }

  render() {
    return (
      <canvas id="main-canvas" ref={this.ref}></canvas>
    );
  }
}

export default MyCanvas;
