import React from 'react';

class Filters extends React.Component {
  render() {
    return (
      <svg height="0" width="0">
        <defs>
          <filter id="waves0" x="-20%" y="-20%" width="140%" height="140%" filterUnits="objectBoundingBox" primitiveUnits="userSpaceOnUse" colorInterpolationFilters="linearRGB"> <feTurbulence type="turbulence" baseFrequency="0.01 0.05" numOctaves="2" seed="2" stitchTiles="noStitch" result="turbulence"/> <feDisplacementMap in="SourceGraphic" in2="turbulence" scale="0" xChannelSelector="G" yChannelSelector="A" result="displacementMap"/> </filter>
          <filter id="waves1" x="-20%" y="-20%" width="140%" height="140%" filterUnits="objectBoundingBox" primitiveUnits="userSpaceOnUse" colorInterpolationFilters="linearRGB"> <feTurbulence type="turbulence" baseFrequency="0.01 0.05" numOctaves="2" seed="2" stitchTiles="noStitch" result="turbulence"/> <feDisplacementMap in="SourceGraphic" in2="turbulence" scale="3" xChannelSelector="G" yChannelSelector="A" result="displacementMap"/> </filter>
          <filter id="waves2" x="-20%" y="-20%" width="140%" height="140%" filterUnits="objectBoundingBox" primitiveUnits="userSpaceOnUse" colorInterpolationFilters="linearRGB"> <feTurbulence type="turbulence" baseFrequency="0.01 0.05" numOctaves="2" seed="2" stitchTiles="noStitch" result="turbulence"/> <feDisplacementMap in="SourceGraphic" in2="turbulence" scale="6" xChannelSelector="G" yChannelSelector="A" result="displacementMap"/> </filter>
          <filter id="waves3" x="-20%" y="-20%" width="140%" height="140%" filterUnits="objectBoundingBox" primitiveUnits="userSpaceOnUse" colorInterpolationFilters="linearRGB"> <feTurbulence type="turbulence" baseFrequency="0.01 0.05" numOctaves="2" seed="2" stitchTiles="noStitch" result="turbulence"/> <feDisplacementMap in="SourceGraphic" in2="turbulence" scale="9" xChannelSelector="G" yChannelSelector="A" result="displacementMap"/> </filter>
          <filter id="waves4" x="-20%" y="-20%" width="140%" height="140%" filterUnits="objectBoundingBox" primitiveUnits="userSpaceOnUse" colorInterpolationFilters="linearRGB"> <feTurbulence type="turbulence" baseFrequency="0.01 0.05" numOctaves="2" seed="2" stitchTiles="noStitch" result="turbulence"/> <feDisplacementMap in="SourceGraphic" in2="turbulence" scale="12" xChannelSelector="G" yChannelSelector="A" result="displacementMap"/> </filter>
          <filter id="waves5" x="-20%" y="-20%" width="140%" height="140%" filterUnits="objectBoundingBox" primitiveUnits="userSpaceOnUse" colorInterpolationFilters="linearRGB"> <feTurbulence type="turbulence" baseFrequency="0.01 0.05" numOctaves="2" seed="2" stitchTiles="noStitch" result="turbulence"/> <feDisplacementMap in="SourceGraphic" in2="turbulence" scale="15" xChannelSelector="G" yChannelSelector="A" result="displacementMap"/> </filter>
          <filter id="waves6" x="-20%" y="-20%" width="140%" height="140%" filterUnits="objectBoundingBox" primitiveUnits="userSpaceOnUse" colorInterpolationFilters="linearRGB"> <feTurbulence type="turbulence" baseFrequency="0.01 0.05" numOctaves="2" seed="2" stitchTiles="noStitch" result="turbulence"/> <feDisplacementMap in="SourceGraphic" in2="turbulence" scale="18" xChannelSelector="G" yChannelSelector="A" result="displacementMap"/> </filter>
          <filter id="waves7" x="-20%" y="-20%" width="140%" height="140%" filterUnits="objectBoundingBox" primitiveUnits="userSpaceOnUse" colorInterpolationFilters="linearRGB"> <feTurbulence type="turbulence" baseFrequency="0.01 0.05" numOctaves="2" seed="2" stitchTiles="noStitch" result="turbulence"/> <feDisplacementMap in="SourceGraphic" in2="turbulence" scale="21" xChannelSelector="G" yChannelSelector="A" result="displacementMap"/> </filter>
          <filter id="waves8" x="-20%" y="-20%" width="140%" height="140%" filterUnits="objectBoundingBox" primitiveUnits="userSpaceOnUse" colorInterpolationFilters="linearRGB"> <feTurbulence type="turbulence" baseFrequency="0.01 0.05" numOctaves="2" seed="2" stitchTiles="noStitch" result="turbulence"/> <feDisplacementMap in="SourceGraphic" in2="turbulence" scale="24" xChannelSelector="G" yChannelSelector="A" result="displacementMap"/> </filter>
          <filter id="waves9" x="-20%" y="-20%" width="140%" height="140%" filterUnits="objectBoundingBox" primitiveUnits="userSpaceOnUse" colorInterpolationFilters="linearRGB"> <feTurbulence type="turbulence" baseFrequency="0.01 0.05" numOctaves="2" seed="2" stitchTiles="noStitch" result="turbulence"/> <feDisplacementMap in="SourceGraphic" in2="turbulence" scale="27" xChannelSelector="G" yChannelSelector="A" result="displacementMap"/> </filter>
          <filter id="waves10" x="-20%" y="-20%" width="140%" height="140%" filterUnits="objectBoundingBox" primitiveUnits="userSpaceOnUse" colorInterpolationFilters="linearRGB"> <feTurbulence type="turbulence" baseFrequency="0.01 0.05" numOctaves="2" seed="2" stitchTiles="noStitch" result="turbulence"/> <feDisplacementMap in="SourceGraphic" in2="turbulence" scale="30" xChannelSelector="G" yChannelSelector="A" result="displacementMap"/> </filter>
          <filter id="waves11" x="-20%" y="-20%" width="140%" height="140%" filterUnits="objectBoundingBox" primitiveUnits="userSpaceOnUse" colorInterpolationFilters="linearRGB"> <feTurbulence type="turbulence" baseFrequency="0.01 0.05" numOctaves="2" seed="2" stitchTiles="noStitch" result="turbulence"/> <feDisplacementMap in="SourceGraphic" in2="turbulence" scale="33" xChannelSelector="G" yChannelSelector="A" result="displacementMap"/> </filter>
          <filter id="waves12" x="-20%" y="-20%" width="140%" height="140%" filterUnits="objectBoundingBox" primitiveUnits="userSpaceOnUse" colorInterpolationFilters="linearRGB"> <feTurbulence type="turbulence" baseFrequency="0.01 0.05" numOctaves="2" seed="2" stitchTiles="noStitch" result="turbulence"/> <feDisplacementMap in="SourceGraphic" in2="turbulence" scale="36" xChannelSelector="G" yChannelSelector="A" result="displacementMap"/> </filter>
          <filter id="waves13" x="-20%" y="-20%" width="140%" height="140%" filterUnits="objectBoundingBox" primitiveUnits="userSpaceOnUse" colorInterpolationFilters="linearRGB"> <feTurbulence type="turbulence" baseFrequency="0.01 0.05" numOctaves="2" seed="2" stitchTiles="noStitch" result="turbulence"/> <feDisplacementMap in="SourceGraphic" in2="turbulence" scale="39" xChannelSelector="G" yChannelSelector="A" result="displacementMap"/> </filter>
          <filter id="waves14" x="-20%" y="-20%" width="140%" height="140%" filterUnits="objectBoundingBox" primitiveUnits="userSpaceOnUse" colorInterpolationFilters="linearRGB"> <feTurbulence type="turbulence" baseFrequency="0.01 0.05" numOctaves="2" seed="2" stitchTiles="noStitch" result="turbulence"/> <feDisplacementMap in="SourceGraphic" in2="turbulence" scale="42" xChannelSelector="G" yChannelSelector="A" result="displacementMap"/> </filter>
          <filter id="waves15" x="-20%" y="-20%" width="140%" height="140%" filterUnits="objectBoundingBox" primitiveUnits="userSpaceOnUse" colorInterpolationFilters="linearRGB"> <feTurbulence type="turbulence" baseFrequency="0.01 0.05" numOctaves="2" seed="2" stitchTiles="noStitch" result="turbulence"/> <feDisplacementMap in="SourceGraphic" in2="turbulence" scale="45" xChannelSelector="G" yChannelSelector="A" result="displacementMap"/> </filter>
          <filter id="waves16" x="-20%" y="-20%" width="140%" height="140%" filterUnits="objectBoundingBox" primitiveUnits="userSpaceOnUse" colorInterpolationFilters="linearRGB"> <feTurbulence type="turbulence" baseFrequency="0.01 0.05" numOctaves="2" seed="2" stitchTiles="noStitch" result="turbulence"/> <feDisplacementMap in="SourceGraphic" in2="turbulence" scale="48" xChannelSelector="G" yChannelSelector="A" result="displacementMap"/> </filter>
          <filter id="waves17" x="-20%" y="-20%" width="140%" height="140%" filterUnits="objectBoundingBox" primitiveUnits="userSpaceOnUse" colorInterpolationFilters="linearRGB"> <feTurbulence type="turbulence" baseFrequency="0.01 0.05" numOctaves="2" seed="2" stitchTiles="noStitch" result="turbulence"/> <feDisplacementMap in="SourceGraphic" in2="turbulence" scale="51" xChannelSelector="G" yChannelSelector="A" result="displacementMap"/> </filter>
          <filter id="waves18" x="-20%" y="-20%" width="140%" height="140%" filterUnits="objectBoundingBox" primitiveUnits="userSpaceOnUse" colorInterpolationFilters="linearRGB"> <feTurbulence type="turbulence" baseFrequency="0.01 0.05" numOctaves="2" seed="2" stitchTiles="noStitch" result="turbulence"/> <feDisplacementMap in="SourceGraphic" in2="turbulence" scale="54" xChannelSelector="G" yChannelSelector="A" result="displacementMap"/> </filter>
          <filter id="waves19" x="-20%" y="-20%" width="140%" height="140%" filterUnits="objectBoundingBox" primitiveUnits="userSpaceOnUse" colorInterpolationFilters="linearRGB"> <feTurbulence type="turbulence" baseFrequency="0.01 0.05" numOctaves="2" seed="2" stitchTiles="noStitch" result="turbulence"/> <feDisplacementMap in="SourceGraphic" in2="turbulence" scale="57" xChannelSelector="G" yChannelSelector="A" result="displacementMap"/> </filter>
          <filter id="waves20" x="-20%" y="-20%" width="140%" height="140%" filterUnits="objectBoundingBox" primitiveUnits="userSpaceOnUse" colorInterpolationFilters="linearRGB"> <feTurbulence type="turbulence" baseFrequency="0.01 0.05" numOctaves="2" seed="2" stitchTiles="noStitch" result="turbulence"/> <feDisplacementMap in="SourceGraphic" in2="turbulence" scale="60" xChannelSelector="G" yChannelSelector="A" result="displacementMap"/> </filter>
          <filter id="waves21" x="-20%" y="-20%" width="140%" height="140%" filterUnits="objectBoundingBox" primitiveUnits="userSpaceOnUse" colorInterpolationFilters="linearRGB"> <feTurbulence type="turbulence" baseFrequency="0.01 0.05" numOctaves="2" seed="2" stitchTiles="noStitch" result="turbulence"/> <feDisplacementMap in="SourceGraphic" in2="turbulence" scale="63" xChannelSelector="G" yChannelSelector="A" result="displacementMap"/> </filter>
          <filter id="waves22" x="-20%" y="-20%" width="140%" height="140%" filterUnits="objectBoundingBox" primitiveUnits="userSpaceOnUse" colorInterpolationFilters="linearRGB"> <feTurbulence type="turbulence" baseFrequency="0.01 0.05" numOctaves="2" seed="2" stitchTiles="noStitch" result="turbulence"/> <feDisplacementMap in="SourceGraphic" in2="turbulence" scale="66" xChannelSelector="G" yChannelSelector="A" result="displacementMap"/> </filter>
          <filter id="waves23" x="-20%" y="-20%" width="140%" height="140%" filterUnits="objectBoundingBox" primitiveUnits="userSpaceOnUse" colorInterpolationFilters="linearRGB"> <feTurbulence type="turbulence" baseFrequency="0.01 0.05" numOctaves="2" seed="2" stitchTiles="noStitch" result="turbulence"/> <feDisplacementMap in="SourceGraphic" in2="turbulence" scale="69" xChannelSelector="G" yChannelSelector="A" result="displacementMap"/> </filter>
          <filter id="waves24" x="-20%" y="-20%" width="140%" height="140%" filterUnits="objectBoundingBox" primitiveUnits="userSpaceOnUse" colorInterpolationFilters="linearRGB"> <feTurbulence type="turbulence" baseFrequency="0.01 0.05" numOctaves="2" seed="2" stitchTiles="noStitch" result="turbulence"/> <feDisplacementMap in="SourceGraphic" in2="turbulence" scale="72" xChannelSelector="G" yChannelSelector="A" result="displacementMap"/> </filter>
          <filter id="waves25" x="-20%" y="-20%" width="140%" height="140%" filterUnits="objectBoundingBox" primitiveUnits="userSpaceOnUse" colorInterpolationFilters="linearRGB"> <feTurbulence type="turbulence" baseFrequency="0.01 0.05" numOctaves="2" seed="2" stitchTiles="noStitch" result="turbulence"/> <feDisplacementMap in="SourceGraphic" in2="turbulence" scale="75" xChannelSelector="G" yChannelSelector="A" result="displacementMap"/> </filter>
          <filter id="waves26" x="-20%" y="-20%" width="140%" height="140%" filterUnits="objectBoundingBox" primitiveUnits="userSpaceOnUse" colorInterpolationFilters="linearRGB"> <feTurbulence type="turbulence" baseFrequency="0.01 0.05" numOctaves="2" seed="2" stitchTiles="noStitch" result="turbulence"/> <feDisplacementMap in="SourceGraphic" in2="turbulence" scale="78" xChannelSelector="G" yChannelSelector="A" result="displacementMap"/> </filter>
          <filter id="waves27" x="-20%" y="-20%" width="140%" height="140%" filterUnits="objectBoundingBox" primitiveUnits="userSpaceOnUse" colorInterpolationFilters="linearRGB"> <feTurbulence type="turbulence" baseFrequency="0.01 0.05" numOctaves="2" seed="2" stitchTiles="noStitch" result="turbulence"/> <feDisplacementMap in="SourceGraphic" in2="turbulence" scale="81" xChannelSelector="G" yChannelSelector="A" result="displacementMap"/> </filter>
          <filter id="waves28" x="-20%" y="-20%" width="140%" height="140%" filterUnits="objectBoundingBox" primitiveUnits="userSpaceOnUse" colorInterpolationFilters="linearRGB"> <feTurbulence type="turbulence" baseFrequency="0.01 0.05" numOctaves="2" seed="2" stitchTiles="noStitch" result="turbulence"/> <feDisplacementMap in="SourceGraphic" in2="turbulence" scale="84" xChannelSelector="G" yChannelSelector="A" result="displacementMap"/> </filter>
          <filter id="waves29" x="-20%" y="-20%" width="140%" height="140%" filterUnits="objectBoundingBox" primitiveUnits="userSpaceOnUse" colorInterpolationFilters="linearRGB"> <feTurbulence type="turbulence" baseFrequency="0.01 0.05" numOctaves="2" seed="2" stitchTiles="noStitch" result="turbulence"/> <feDisplacementMap in="SourceGraphic" in2="turbulence" scale="87" xChannelSelector="G" yChannelSelector="A" result="displacementMap"/> </filter>
          <filter id="waves30" x="-20%" y="-20%" width="140%" height="140%" filterUnits="objectBoundingBox" primitiveUnits="userSpaceOnUse" colorInterpolationFilters="linearRGB"> <feTurbulence type="turbulence" baseFrequency="0.01 0.05" numOctaves="2" seed="2" stitchTiles="noStitch" result="turbulence"/> <feDisplacementMap in="SourceGraphic" in2="turbulence" scale="90" xChannelSelector="G" yChannelSelector="A" result="displacementMap"/> </filter>
          <filter id="waves31" x="-20%" y="-20%" width="140%" height="140%" filterUnits="objectBoundingBox" primitiveUnits="userSpaceOnUse" colorInterpolationFilters="linearRGB"> <feTurbulence type="turbulence" baseFrequency="0.01 0.05" numOctaves="2" seed="2" stitchTiles="noStitch" result="turbulence"/> <feDisplacementMap in="SourceGraphic" in2="turbulence" scale="93" xChannelSelector="G" yChannelSelector="A" result="displacementMap"/> </filter>
          <filter id="waves32" x="-20%" y="-20%" width="140%" height="140%" filterUnits="objectBoundingBox" primitiveUnits="userSpaceOnUse" colorInterpolationFilters="linearRGB"> <feTurbulence type="turbulence" baseFrequency="0.01 0.05" numOctaves="2" seed="2" stitchTiles="noStitch" result="turbulence"/> <feDisplacementMap in="SourceGraphic" in2="turbulence" scale="96" xChannelSelector="G" yChannelSelector="A" result="displacementMap"/> </filter>
          <filter id="waves33" x="-20%" y="-20%" width="140%" height="140%" filterUnits="objectBoundingBox" primitiveUnits="userSpaceOnUse" colorInterpolationFilters="linearRGB"> <feTurbulence type="turbulence" baseFrequency="0.01 0.05" numOctaves="2" seed="2" stitchTiles="noStitch" result="turbulence"/> <feDisplacementMap in="SourceGraphic" in2="turbulence" scale="99" xChannelSelector="G" yChannelSelector="A" result="displacementMap"/> </filter>
          <filter id="waves34" x="-20%" y="-20%" width="140%" height="140%" filterUnits="objectBoundingBox" primitiveUnits="userSpaceOnUse" colorInterpolationFilters="linearRGB"> <feTurbulence type="turbulence" baseFrequency="0.01 0.05" numOctaves="2" seed="2" stitchTiles="noStitch" result="turbulence"/> <feDisplacementMap in="SourceGraphic" in2="turbulence" scale="102" xChannelSelector="G" yChannelSelector="A" result="displacementMap"/> </filter>
          <filter id="waves35" x="-20%" y="-20%" width="140%" height="140%" filterUnits="objectBoundingBox" primitiveUnits="userSpaceOnUse" colorInterpolationFilters="linearRGB"> <feTurbulence type="turbulence" baseFrequency="0.01 0.05" numOctaves="2" seed="2" stitchTiles="noStitch" result="turbulence"/> <feDisplacementMap in="SourceGraphic" in2="turbulence" scale="105" xChannelSelector="G" yChannelSelector="A" result="displacementMap"/> </filter>
          <filter id="waves36" x="-20%" y="-20%" width="140%" height="140%" filterUnits="objectBoundingBox" primitiveUnits="userSpaceOnUse" colorInterpolationFilters="linearRGB"> <feTurbulence type="turbulence" baseFrequency="0.01 0.05" numOctaves="2" seed="2" stitchTiles="noStitch" result="turbulence"/> <feDisplacementMap in="SourceGraphic" in2="turbulence" scale="108" xChannelSelector="G" yChannelSelector="A" result="displacementMap"/> </filter>
          <filter id="waves37" x="-20%" y="-20%" width="140%" height="140%" filterUnits="objectBoundingBox" primitiveUnits="userSpaceOnUse" colorInterpolationFilters="linearRGB"> <feTurbulence type="turbulence" baseFrequency="0.01 0.05" numOctaves="2" seed="2" stitchTiles="noStitch" result="turbulence"/> <feDisplacementMap in="SourceGraphic" in2="turbulence" scale="111" xChannelSelector="G" yChannelSelector="A" result="displacementMap"/> </filter>
          <filter id="waves38" x="-20%" y="-20%" width="140%" height="140%" filterUnits="objectBoundingBox" primitiveUnits="userSpaceOnUse" colorInterpolationFilters="linearRGB"> <feTurbulence type="turbulence" baseFrequency="0.01 0.05" numOctaves="2" seed="2" stitchTiles="noStitch" result="turbulence"/> <feDisplacementMap in="SourceGraphic" in2="turbulence" scale="114" xChannelSelector="G" yChannelSelector="A" result="displacementMap"/> </filter>
          <filter id="waves39" x="-20%" y="-20%" width="140%" height="140%" filterUnits="objectBoundingBox" primitiveUnits="userSpaceOnUse" colorInterpolationFilters="linearRGB"> <feTurbulence type="turbulence" baseFrequency="0.01 0.05" numOctaves="2" seed="2" stitchTiles="noStitch" result="turbulence"/> <feDisplacementMap in="SourceGraphic" in2="turbulence" scale="117" xChannelSelector="G" yChannelSelector="A" result="displacementMap"/> </filter>
          <filter id="waves40" x="-20%" y="-20%" width="140%" height="140%" filterUnits="objectBoundingBox" primitiveUnits="userSpaceOnUse" colorInterpolationFilters="linearRGB"> <feTurbulence type="turbulence" baseFrequency="0.01 0.05" numOctaves="2" seed="2" stitchTiles="noStitch" result="turbulence"/> <feDisplacementMap in="SourceGraphic" in2="turbulence" scale="120" xChannelSelector="G" yChannelSelector="A" result="displacementMap"/> </filter>
          <filter id="waves41" x="-20%" y="-20%" width="140%" height="140%" filterUnits="objectBoundingBox" primitiveUnits="userSpaceOnUse" colorInterpolationFilters="linearRGB"> <feTurbulence type="turbulence" baseFrequency="0.01 0.05" numOctaves="2" seed="2" stitchTiles="noStitch" result="turbulence"/> <feDisplacementMap in="SourceGraphic" in2="turbulence" scale="123" xChannelSelector="G" yChannelSelector="A" result="displacementMap"/> </filter>
          <filter id="waves42" x="-20%" y="-20%" width="140%" height="140%" filterUnits="objectBoundingBox" primitiveUnits="userSpaceOnUse" colorInterpolationFilters="linearRGB"> <feTurbulence type="turbulence" baseFrequency="0.01 0.05" numOctaves="2" seed="2" stitchTiles="noStitch" result="turbulence"/> <feDisplacementMap in="SourceGraphic" in2="turbulence" scale="126" xChannelSelector="G" yChannelSelector="A" result="displacementMap"/> </filter>
          <filter id="waves43" x="-20%" y="-20%" width="140%" height="140%" filterUnits="objectBoundingBox" primitiveUnits="userSpaceOnUse" colorInterpolationFilters="linearRGB"> <feTurbulence type="turbulence" baseFrequency="0.01 0.05" numOctaves="2" seed="2" stitchTiles="noStitch" result="turbulence"/> <feDisplacementMap in="SourceGraphic" in2="turbulence" scale="129" xChannelSelector="G" yChannelSelector="A" result="displacementMap"/> </filter>
          <filter id="waves44" x="-20%" y="-20%" width="140%" height="140%" filterUnits="objectBoundingBox" primitiveUnits="userSpaceOnUse" colorInterpolationFilters="linearRGB"> <feTurbulence type="turbulence" baseFrequency="0.01 0.05" numOctaves="2" seed="2" stitchTiles="noStitch" result="turbulence"/> <feDisplacementMap in="SourceGraphic" in2="turbulence" scale="132" xChannelSelector="G" yChannelSelector="A" result="displacementMap"/> </filter>
          <filter id="waves45" x="-20%" y="-20%" width="140%" height="140%" filterUnits="objectBoundingBox" primitiveUnits="userSpaceOnUse" colorInterpolationFilters="linearRGB"> <feTurbulence type="turbulence" baseFrequency="0.01 0.05" numOctaves="2" seed="2" stitchTiles="noStitch" result="turbulence"/> <feDisplacementMap in="SourceGraphic" in2="turbulence" scale="135" xChannelSelector="G" yChannelSelector="A" result="displacementMap"/> </filter>
          <filter id="waves46" x="-20%" y="-20%" width="140%" height="140%" filterUnits="objectBoundingBox" primitiveUnits="userSpaceOnUse" colorInterpolationFilters="linearRGB"> <feTurbulence type="turbulence" baseFrequency="0.01 0.05" numOctaves="2" seed="2" stitchTiles="noStitch" result="turbulence"/> <feDisplacementMap in="SourceGraphic" in2="turbulence" scale="138" xChannelSelector="G" yChannelSelector="A" result="displacementMap"/> </filter>
          <filter id="waves47" x="-20%" y="-20%" width="140%" height="140%" filterUnits="objectBoundingBox" primitiveUnits="userSpaceOnUse" colorInterpolationFilters="linearRGB"> <feTurbulence type="turbulence" baseFrequency="0.01 0.05" numOctaves="2" seed="2" stitchTiles="noStitch" result="turbulence"/> <feDisplacementMap in="SourceGraphic" in2="turbulence" scale="141" xChannelSelector="G" yChannelSelector="A" result="displacementMap"/> </filter>
          <filter id="waves48" x="-20%" y="-20%" width="140%" height="140%" filterUnits="objectBoundingBox" primitiveUnits="userSpaceOnUse" colorInterpolationFilters="linearRGB"> <feTurbulence type="turbulence" baseFrequency="0.01 0.05" numOctaves="2" seed="2" stitchTiles="noStitch" result="turbulence"/> <feDisplacementMap in="SourceGraphic" in2="turbulence" scale="144" xChannelSelector="G" yChannelSelector="A" result="displacementMap"/> </filter>
          <filter id="waves49" x="-20%" y="-20%" width="140%" height="140%" filterUnits="objectBoundingBox" primitiveUnits="userSpaceOnUse" colorInterpolationFilters="linearRGB"> <feTurbulence type="turbulence" baseFrequency="0.01 0.05" numOctaves="2" seed="2" stitchTiles="noStitch" result="turbulence"/> <feDisplacementMap in="SourceGraphic" in2="turbulence" scale="147" xChannelSelector="G" yChannelSelector="A" result="displacementMap"/> </filter>
          <filter id="waves50" x="-20%" y="-20%" width="140%" height="140%" filterUnits="objectBoundingBox" primitiveUnits="userSpaceOnUse" colorInterpolationFilters="linearRGB"> <feTurbulence type="turbulence" baseFrequency="0.01 0.05" numOctaves="2" seed="2" stitchTiles="noStitch" result="turbulence"/> <feDisplacementMap in="SourceGraphic" in2="turbulence" scale="150" xChannelSelector="G" yChannelSelector="A" result="displacementMap"/> </filter>
          <filter id="waves51" x="-20%" y="-20%" width="140%" height="140%" filterUnits="objectBoundingBox" primitiveUnits="userSpaceOnUse" colorInterpolationFilters="linearRGB"> <feTurbulence type="turbulence" baseFrequency="0.01 0.05" numOctaves="2" seed="2" stitchTiles="noStitch" result="turbulence"/> <feDisplacementMap in="SourceGraphic" in2="turbulence" scale="153" xChannelSelector="G" yChannelSelector="A" result="displacementMap"/> </filter>
          <filter id="waves52" x="-20%" y="-20%" width="140%" height="140%" filterUnits="objectBoundingBox" primitiveUnits="userSpaceOnUse" colorInterpolationFilters="linearRGB"> <feTurbulence type="turbulence" baseFrequency="0.01 0.05" numOctaves="2" seed="2" stitchTiles="noStitch" result="turbulence"/> <feDisplacementMap in="SourceGraphic" in2="turbulence" scale="156" xChannelSelector="G" yChannelSelector="A" result="displacementMap"/> </filter>
          <filter id="waves53" x="-20%" y="-20%" width="140%" height="140%" filterUnits="objectBoundingBox" primitiveUnits="userSpaceOnUse" colorInterpolationFilters="linearRGB"> <feTurbulence type="turbulence" baseFrequency="0.01 0.05" numOctaves="2" seed="2" stitchTiles="noStitch" result="turbulence"/> <feDisplacementMap in="SourceGraphic" in2="turbulence" scale="159" xChannelSelector="G" yChannelSelector="A" result="displacementMap"/> </filter>
          <filter id="waves54" x="-20%" y="-20%" width="140%" height="140%" filterUnits="objectBoundingBox" primitiveUnits="userSpaceOnUse" colorInterpolationFilters="linearRGB"> <feTurbulence type="turbulence" baseFrequency="0.01 0.05" numOctaves="2" seed="2" stitchTiles="noStitch" result="turbulence"/> <feDisplacementMap in="SourceGraphic" in2="turbulence" scale="162" xChannelSelector="G" yChannelSelector="A" result="displacementMap"/> </filter>
          <filter id="waves55" x="-20%" y="-20%" width="140%" height="140%" filterUnits="objectBoundingBox" primitiveUnits="userSpaceOnUse" colorInterpolationFilters="linearRGB"> <feTurbulence type="turbulence" baseFrequency="0.01 0.05" numOctaves="2" seed="2" stitchTiles="noStitch" result="turbulence"/> <feDisplacementMap in="SourceGraphic" in2="turbulence" scale="165" xChannelSelector="G" yChannelSelector="A" result="displacementMap"/> </filter>
          <filter id="waves56" x="-20%" y="-20%" width="140%" height="140%" filterUnits="objectBoundingBox" primitiveUnits="userSpaceOnUse" colorInterpolationFilters="linearRGB"> <feTurbulence type="turbulence" baseFrequency="0.01 0.05" numOctaves="2" seed="2" stitchTiles="noStitch" result="turbulence"/> <feDisplacementMap in="SourceGraphic" in2="turbulence" scale="168" xChannelSelector="G" yChannelSelector="A" result="displacementMap"/> </filter>
          <filter id="waves57" x="-20%" y="-20%" width="140%" height="140%" filterUnits="objectBoundingBox" primitiveUnits="userSpaceOnUse" colorInterpolationFilters="linearRGB"> <feTurbulence type="turbulence" baseFrequency="0.01 0.05" numOctaves="2" seed="2" stitchTiles="noStitch" result="turbulence"/> <feDisplacementMap in="SourceGraphic" in2="turbulence" scale="171" xChannelSelector="G" yChannelSelector="A" result="displacementMap"/> </filter>
          <filter id="waves58" x="-20%" y="-20%" width="140%" height="140%" filterUnits="objectBoundingBox" primitiveUnits="userSpaceOnUse" colorInterpolationFilters="linearRGB"> <feTurbulence type="turbulence" baseFrequency="0.01 0.05" numOctaves="2" seed="2" stitchTiles="noStitch" result="turbulence"/> <feDisplacementMap in="SourceGraphic" in2="turbulence" scale="174" xChannelSelector="G" yChannelSelector="A" result="displacementMap"/> </filter>
          <filter id="waves59" x="-20%" y="-20%" width="140%" height="140%" filterUnits="objectBoundingBox" primitiveUnits="userSpaceOnUse" colorInterpolationFilters="linearRGB"> <feTurbulence type="turbulence" baseFrequency="0.01 0.05" numOctaves="2" seed="2" stitchTiles="noStitch" result="turbulence"/> <feDisplacementMap in="SourceGraphic" in2="turbulence" scale="177" xChannelSelector="G" yChannelSelector="A" result="displacementMap"/> </filter>
          <filter id="waves60" x="-20%" y="-20%" width="140%" height="140%" filterUnits="objectBoundingBox" primitiveUnits="userSpaceOnUse" colorInterpolationFilters="linearRGB"> <feTurbulence type="turbulence" baseFrequency="0.01 0.05" numOctaves="2" seed="2" stitchTiles="noStitch" result="turbulence"/> <feDisplacementMap in="SourceGraphic" in2="turbulence" scale="180" xChannelSelector="G" yChannelSelector="A" result="displacementMap"/> </filter>
          <filter id="waves61" x="-20%" y="-20%" width="140%" height="140%" filterUnits="objectBoundingBox" primitiveUnits="userSpaceOnUse" colorInterpolationFilters="linearRGB"> <feTurbulence type="turbulence" baseFrequency="0.01 0.05" numOctaves="2" seed="2" stitchTiles="noStitch" result="turbulence"/> <feDisplacementMap in="SourceGraphic" in2="turbulence" scale="183" xChannelSelector="G" yChannelSelector="A" result="displacementMap"/> </filter>
          <filter id="waves62" x="-20%" y="-20%" width="140%" height="140%" filterUnits="objectBoundingBox" primitiveUnits="userSpaceOnUse" colorInterpolationFilters="linearRGB"> <feTurbulence type="turbulence" baseFrequency="0.01 0.05" numOctaves="2" seed="2" stitchTiles="noStitch" result="turbulence"/> <feDisplacementMap in="SourceGraphic" in2="turbulence" scale="186" xChannelSelector="G" yChannelSelector="A" result="displacementMap"/> </filter>
          <filter id="waves63" x="-20%" y="-20%" width="140%" height="140%" filterUnits="objectBoundingBox" primitiveUnits="userSpaceOnUse" colorInterpolationFilters="linearRGB"> <feTurbulence type="turbulence" baseFrequency="0.01 0.05" numOctaves="2" seed="2" stitchTiles="noStitch" result="turbulence"/> <feDisplacementMap in="SourceGraphic" in2="turbulence" scale="189" xChannelSelector="G" yChannelSelector="A" result="displacementMap"/> </filter>
          <filter id="waves64" x="-20%" y="-20%" width="140%" height="140%" filterUnits="objectBoundingBox" primitiveUnits="userSpaceOnUse" colorInterpolationFilters="linearRGB"> <feTurbulence type="turbulence" baseFrequency="0.01 0.05" numOctaves="2" seed="2" stitchTiles="noStitch" result="turbulence"/> <feDisplacementMap in="SourceGraphic" in2="turbulence" scale="192" xChannelSelector="G" yChannelSelector="A" result="displacementMap"/> </filter>
          <filter id="waves65" x="-20%" y="-20%" width="140%" height="140%" filterUnits="objectBoundingBox" primitiveUnits="userSpaceOnUse" colorInterpolationFilters="linearRGB"> <feTurbulence type="turbulence" baseFrequency="0.01 0.05" numOctaves="2" seed="2" stitchTiles="noStitch" result="turbulence"/> <feDisplacementMap in="SourceGraphic" in2="turbulence" scale="195" xChannelSelector="G" yChannelSelector="A" result="displacementMap"/> </filter>
          <filter id="waves66" x="-20%" y="-20%" width="140%" height="140%" filterUnits="objectBoundingBox" primitiveUnits="userSpaceOnUse" colorInterpolationFilters="linearRGB"> <feTurbulence type="turbulence" baseFrequency="0.01 0.05" numOctaves="2" seed="2" stitchTiles="noStitch" result="turbulence"/> <feDisplacementMap in="SourceGraphic" in2="turbulence" scale="198" xChannelSelector="G" yChannelSelector="A" result="displacementMap"/> </filter>
          <filter id="waves67" x="-20%" y="-20%" width="140%" height="140%" filterUnits="objectBoundingBox" primitiveUnits="userSpaceOnUse" colorInterpolationFilters="linearRGB"> <feTurbulence type="turbulence" baseFrequency="0.01 0.05" numOctaves="2" seed="2" stitchTiles="noStitch" result="turbulence"/> <feDisplacementMap in="SourceGraphic" in2="turbulence" scale="201" xChannelSelector="G" yChannelSelector="A" result="displacementMap"/> </filter>
          <filter id="waves68" x="-20%" y="-20%" width="140%" height="140%" filterUnits="objectBoundingBox" primitiveUnits="userSpaceOnUse" colorInterpolationFilters="linearRGB"> <feTurbulence type="turbulence" baseFrequency="0.01 0.05" numOctaves="2" seed="2" stitchTiles="noStitch" result="turbulence"/> <feDisplacementMap in="SourceGraphic" in2="turbulence" scale="204" xChannelSelector="G" yChannelSelector="A" result="displacementMap"/> </filter>
          <filter id="waves69" x="-20%" y="-20%" width="140%" height="140%" filterUnits="objectBoundingBox" primitiveUnits="userSpaceOnUse" colorInterpolationFilters="linearRGB"> <feTurbulence type="turbulence" baseFrequency="0.01 0.05" numOctaves="2" seed="2" stitchTiles="noStitch" result="turbulence"/> <feDisplacementMap in="SourceGraphic" in2="turbulence" scale="207" xChannelSelector="G" yChannelSelector="A" result="displacementMap"/> </filter>
          <filter id="waves70" x="-20%" y="-20%" width="140%" height="140%" filterUnits="objectBoundingBox" primitiveUnits="userSpaceOnUse" colorInterpolationFilters="linearRGB"> <feTurbulence type="turbulence" baseFrequency="0.01 0.05" numOctaves="2" seed="2" stitchTiles="noStitch" result="turbulence"/> <feDisplacementMap in="SourceGraphic" in2="turbulence" scale="210" xChannelSelector="G" yChannelSelector="A" result="displacementMap"/> </filter>
          <filter id="waves71" x="-20%" y="-20%" width="140%" height="140%" filterUnits="objectBoundingBox" primitiveUnits="userSpaceOnUse" colorInterpolationFilters="linearRGB"> <feTurbulence type="turbulence" baseFrequency="0.01 0.05" numOctaves="2" seed="2" stitchTiles="noStitch" result="turbulence"/> <feDisplacementMap in="SourceGraphic" in2="turbulence" scale="213" xChannelSelector="G" yChannelSelector="A" result="displacementMap"/> </filter>
          <filter id="waves72" x="-20%" y="-20%" width="140%" height="140%" filterUnits="objectBoundingBox" primitiveUnits="userSpaceOnUse" colorInterpolationFilters="linearRGB"> <feTurbulence type="turbulence" baseFrequency="0.01 0.05" numOctaves="2" seed="2" stitchTiles="noStitch" result="turbulence"/> <feDisplacementMap in="SourceGraphic" in2="turbulence" scale="216" xChannelSelector="G" yChannelSelector="A" result="displacementMap"/> </filter>
          <filter id="waves73" x="-20%" y="-20%" width="140%" height="140%" filterUnits="objectBoundingBox" primitiveUnits="userSpaceOnUse" colorInterpolationFilters="linearRGB"> <feTurbulence type="turbulence" baseFrequency="0.01 0.05" numOctaves="2" seed="2" stitchTiles="noStitch" result="turbulence"/> <feDisplacementMap in="SourceGraphic" in2="turbulence" scale="219" xChannelSelector="G" yChannelSelector="A" result="displacementMap"/> </filter>
          <filter id="waves74" x="-20%" y="-20%" width="140%" height="140%" filterUnits="objectBoundingBox" primitiveUnits="userSpaceOnUse" colorInterpolationFilters="linearRGB"> <feTurbulence type="turbulence" baseFrequency="0.01 0.05" numOctaves="2" seed="2" stitchTiles="noStitch" result="turbulence"/> <feDisplacementMap in="SourceGraphic" in2="turbulence" scale="222" xChannelSelector="G" yChannelSelector="A" result="displacementMap"/> </filter>
          <filter id="waves75" x="-20%" y="-20%" width="140%" height="140%" filterUnits="objectBoundingBox" primitiveUnits="userSpaceOnUse" colorInterpolationFilters="linearRGB"> <feTurbulence type="turbulence" baseFrequency="0.01 0.05" numOctaves="2" seed="2" stitchTiles="noStitch" result="turbulence"/> <feDisplacementMap in="SourceGraphic" in2="turbulence" scale="225" xChannelSelector="G" yChannelSelector="A" result="displacementMap"/> </filter>
          <filter id="waves76" x="-20%" y="-20%" width="140%" height="140%" filterUnits="objectBoundingBox" primitiveUnits="userSpaceOnUse" colorInterpolationFilters="linearRGB"> <feTurbulence type="turbulence" baseFrequency="0.01 0.05" numOctaves="2" seed="2" stitchTiles="noStitch" result="turbulence"/> <feDisplacementMap in="SourceGraphic" in2="turbulence" scale="228" xChannelSelector="G" yChannelSelector="A" result="displacementMap"/> </filter>
          <filter id="waves77" x="-20%" y="-20%" width="140%" height="140%" filterUnits="objectBoundingBox" primitiveUnits="userSpaceOnUse" colorInterpolationFilters="linearRGB"> <feTurbulence type="turbulence" baseFrequency="0.01 0.05" numOctaves="2" seed="2" stitchTiles="noStitch" result="turbulence"/> <feDisplacementMap in="SourceGraphic" in2="turbulence" scale="231" xChannelSelector="G" yChannelSelector="A" result="displacementMap"/> </filter>
          <filter id="waves78" x="-20%" y="-20%" width="140%" height="140%" filterUnits="objectBoundingBox" primitiveUnits="userSpaceOnUse" colorInterpolationFilters="linearRGB"> <feTurbulence type="turbulence" baseFrequency="0.01 0.05" numOctaves="2" seed="2" stitchTiles="noStitch" result="turbulence"/> <feDisplacementMap in="SourceGraphic" in2="turbulence" scale="234" xChannelSelector="G" yChannelSelector="A" result="displacementMap"/> </filter>
          <filter id="waves79" x="-20%" y="-20%" width="140%" height="140%" filterUnits="objectBoundingBox" primitiveUnits="userSpaceOnUse" colorInterpolationFilters="linearRGB"> <feTurbulence type="turbulence" baseFrequency="0.01 0.05" numOctaves="2" seed="2" stitchTiles="noStitch" result="turbulence"/> <feDisplacementMap in="SourceGraphic" in2="turbulence" scale="237" xChannelSelector="G" yChannelSelector="A" result="displacementMap"/> </filter>
          <filter id="waves80" x="-20%" y="-20%" width="140%" height="140%" filterUnits="objectBoundingBox" primitiveUnits="userSpaceOnUse" colorInterpolationFilters="linearRGB"> <feTurbulence type="turbulence" baseFrequency="0.01 0.05" numOctaves="2" seed="2" stitchTiles="noStitch" result="turbulence"/> <feDisplacementMap in="SourceGraphic" in2="turbulence" scale="240" xChannelSelector="G" yChannelSelector="A" result="displacementMap"/> </filter>
          <filter id="waves81" x="-20%" y="-20%" width="140%" height="140%" filterUnits="objectBoundingBox" primitiveUnits="userSpaceOnUse" colorInterpolationFilters="linearRGB"> <feTurbulence type="turbulence" baseFrequency="0.01 0.05" numOctaves="2" seed="2" stitchTiles="noStitch" result="turbulence"/> <feDisplacementMap in="SourceGraphic" in2="turbulence" scale="243" xChannelSelector="G" yChannelSelector="A" result="displacementMap"/> </filter>
          <filter id="waves82" x="-20%" y="-20%" width="140%" height="140%" filterUnits="objectBoundingBox" primitiveUnits="userSpaceOnUse" colorInterpolationFilters="linearRGB"> <feTurbulence type="turbulence" baseFrequency="0.01 0.05" numOctaves="2" seed="2" stitchTiles="noStitch" result="turbulence"/> <feDisplacementMap in="SourceGraphic" in2="turbulence" scale="246" xChannelSelector="G" yChannelSelector="A" result="displacementMap"/> </filter>
          <filter id="waves83" x="-20%" y="-20%" width="140%" height="140%" filterUnits="objectBoundingBox" primitiveUnits="userSpaceOnUse" colorInterpolationFilters="linearRGB"> <feTurbulence type="turbulence" baseFrequency="0.01 0.05" numOctaves="2" seed="2" stitchTiles="noStitch" result="turbulence"/> <feDisplacementMap in="SourceGraphic" in2="turbulence" scale="249" xChannelSelector="G" yChannelSelector="A" result="displacementMap"/> </filter>
          <filter id="waves84" x="-20%" y="-20%" width="140%" height="140%" filterUnits="objectBoundingBox" primitiveUnits="userSpaceOnUse" colorInterpolationFilters="linearRGB"> <feTurbulence type="turbulence" baseFrequency="0.01 0.05" numOctaves="2" seed="2" stitchTiles="noStitch" result="turbulence"/> <feDisplacementMap in="SourceGraphic" in2="turbulence" scale="252" xChannelSelector="G" yChannelSelector="A" result="displacementMap"/> </filter>
          <filter id="waves85" x="-20%" y="-20%" width="140%" height="140%" filterUnits="objectBoundingBox" primitiveUnits="userSpaceOnUse" colorInterpolationFilters="linearRGB"> <feTurbulence type="turbulence" baseFrequency="0.01 0.05" numOctaves="2" seed="2" stitchTiles="noStitch" result="turbulence"/> <feDisplacementMap in="SourceGraphic" in2="turbulence" scale="255" xChannelSelector="G" yChannelSelector="A" result="displacementMap"/> </filter>
          <filter id="waves86" x="-20%" y="-20%" width="140%" height="140%" filterUnits="objectBoundingBox" primitiveUnits="userSpaceOnUse" colorInterpolationFilters="linearRGB"> <feTurbulence type="turbulence" baseFrequency="0.01 0.05" numOctaves="2" seed="2" stitchTiles="noStitch" result="turbulence"/> <feDisplacementMap in="SourceGraphic" in2="turbulence" scale="258" xChannelSelector="G" yChannelSelector="A" result="displacementMap"/> </filter>
          <filter id="waves87" x="-20%" y="-20%" width="140%" height="140%" filterUnits="objectBoundingBox" primitiveUnits="userSpaceOnUse" colorInterpolationFilters="linearRGB"> <feTurbulence type="turbulence" baseFrequency="0.01 0.05" numOctaves="2" seed="2" stitchTiles="noStitch" result="turbulence"/> <feDisplacementMap in="SourceGraphic" in2="turbulence" scale="261" xChannelSelector="G" yChannelSelector="A" result="displacementMap"/> </filter>
          <filter id="waves88" x="-20%" y="-20%" width="140%" height="140%" filterUnits="objectBoundingBox" primitiveUnits="userSpaceOnUse" colorInterpolationFilters="linearRGB"> <feTurbulence type="turbulence" baseFrequency="0.01 0.05" numOctaves="2" seed="2" stitchTiles="noStitch" result="turbulence"/> <feDisplacementMap in="SourceGraphic" in2="turbulence" scale="264" xChannelSelector="G" yChannelSelector="A" result="displacementMap"/> </filter>
          <filter id="waves89" x="-20%" y="-20%" width="140%" height="140%" filterUnits="objectBoundingBox" primitiveUnits="userSpaceOnUse" colorInterpolationFilters="linearRGB"> <feTurbulence type="turbulence" baseFrequency="0.01 0.05" numOctaves="2" seed="2" stitchTiles="noStitch" result="turbulence"/> <feDisplacementMap in="SourceGraphic" in2="turbulence" scale="267" xChannelSelector="G" yChannelSelector="A" result="displacementMap"/> </filter>
          <filter id="waves90" x="-20%" y="-20%" width="140%" height="140%" filterUnits="objectBoundingBox" primitiveUnits="userSpaceOnUse" colorInterpolationFilters="linearRGB"> <feTurbulence type="turbulence" baseFrequency="0.01 0.05" numOctaves="2" seed="2" stitchTiles="noStitch" result="turbulence"/> <feDisplacementMap in="SourceGraphic" in2="turbulence" scale="270" xChannelSelector="G" yChannelSelector="A" result="displacementMap"/> </filter>
          <filter id="waves91" x="-20%" y="-20%" width="140%" height="140%" filterUnits="objectBoundingBox" primitiveUnits="userSpaceOnUse" colorInterpolationFilters="linearRGB"> <feTurbulence type="turbulence" baseFrequency="0.01 0.05" numOctaves="2" seed="2" stitchTiles="noStitch" result="turbulence"/> <feDisplacementMap in="SourceGraphic" in2="turbulence" scale="273" xChannelSelector="G" yChannelSelector="A" result="displacementMap"/> </filter>
          <filter id="waves92" x="-20%" y="-20%" width="140%" height="140%" filterUnits="objectBoundingBox" primitiveUnits="userSpaceOnUse" colorInterpolationFilters="linearRGB"> <feTurbulence type="turbulence" baseFrequency="0.01 0.05" numOctaves="2" seed="2" stitchTiles="noStitch" result="turbulence"/> <feDisplacementMap in="SourceGraphic" in2="turbulence" scale="276" xChannelSelector="G" yChannelSelector="A" result="displacementMap"/> </filter>
          <filter id="waves93" x="-20%" y="-20%" width="140%" height="140%" filterUnits="objectBoundingBox" primitiveUnits="userSpaceOnUse" colorInterpolationFilters="linearRGB"> <feTurbulence type="turbulence" baseFrequency="0.01 0.05" numOctaves="2" seed="2" stitchTiles="noStitch" result="turbulence"/> <feDisplacementMap in="SourceGraphic" in2="turbulence" scale="279" xChannelSelector="G" yChannelSelector="A" result="displacementMap"/> </filter>
          <filter id="waves94" x="-20%" y="-20%" width="140%" height="140%" filterUnits="objectBoundingBox" primitiveUnits="userSpaceOnUse" colorInterpolationFilters="linearRGB"> <feTurbulence type="turbulence" baseFrequency="0.01 0.05" numOctaves="2" seed="2" stitchTiles="noStitch" result="turbulence"/> <feDisplacementMap in="SourceGraphic" in2="turbulence" scale="282" xChannelSelector="G" yChannelSelector="A" result="displacementMap"/> </filter>
          <filter id="waves95" x="-20%" y="-20%" width="140%" height="140%" filterUnits="objectBoundingBox" primitiveUnits="userSpaceOnUse" colorInterpolationFilters="linearRGB"> <feTurbulence type="turbulence" baseFrequency="0.01 0.05" numOctaves="2" seed="2" stitchTiles="noStitch" result="turbulence"/> <feDisplacementMap in="SourceGraphic" in2="turbulence" scale="285" xChannelSelector="G" yChannelSelector="A" result="displacementMap"/> </filter>
          <filter id="waves96" x="-20%" y="-20%" width="140%" height="140%" filterUnits="objectBoundingBox" primitiveUnits="userSpaceOnUse" colorInterpolationFilters="linearRGB"> <feTurbulence type="turbulence" baseFrequency="0.01 0.05" numOctaves="2" seed="2" stitchTiles="noStitch" result="turbulence"/> <feDisplacementMap in="SourceGraphic" in2="turbulence" scale="288" xChannelSelector="G" yChannelSelector="A" result="displacementMap"/> </filter>
          <filter id="waves97" x="-20%" y="-20%" width="140%" height="140%" filterUnits="objectBoundingBox" primitiveUnits="userSpaceOnUse" colorInterpolationFilters="linearRGB"> <feTurbulence type="turbulence" baseFrequency="0.01 0.05" numOctaves="2" seed="2" stitchTiles="noStitch" result="turbulence"/> <feDisplacementMap in="SourceGraphic" in2="turbulence" scale="291" xChannelSelector="G" yChannelSelector="A" result="displacementMap"/> </filter>
          <filter id="waves98" x="-20%" y="-20%" width="140%" height="140%" filterUnits="objectBoundingBox" primitiveUnits="userSpaceOnUse" colorInterpolationFilters="linearRGB"> <feTurbulence type="turbulence" baseFrequency="0.01 0.05" numOctaves="2" seed="2" stitchTiles="noStitch" result="turbulence"/> <feDisplacementMap in="SourceGraphic" in2="turbulence" scale="294" xChannelSelector="G" yChannelSelector="A" result="displacementMap"/> </filter>
          <filter id="waves99" x="-20%" y="-20%" width="140%" height="140%" filterUnits="objectBoundingBox" primitiveUnits="userSpaceOnUse" colorInterpolationFilters="linearRGB"> <feTurbulence type="turbulence" baseFrequency="0.01 0.05" numOctaves="2" seed="2" stitchTiles="noStitch" result="turbulence"/> <feDisplacementMap in="SourceGraphic" in2="turbulence" scale="297" xChannelSelector="G" yChannelSelector="A" result="displacementMap"/> </filter>
          <filter id="waves100" x="-20%" y="-20%" width="140%" height="140%" filterUnits="objectBoundingBox" primitiveUnits="userSpaceOnUse" colorInterpolationFilters="linearRGB"> <feTurbulence type="turbulence" baseFrequency="0.01 0.05" numOctaves="2" seed="2" stitchTiles="noStitch" result="turbulence"/> <feDisplacementMap in="SourceGraphic" in2="turbulence" scale="300" xChannelSelector="G" yChannelSelector="A" result="displacementMap"/> </filter>
          <filter id="colorcubes" x="-20%" y="-20%" width="140%" height="140%" filterUnits="objectBoundingBox" primitiveUnits="userSpaceOnUse" colorInterpolationFilters="linearRGB">
          	<feTurbulence type="turbulence" baseFrequency="0.015 0.015" numOctaves="3" seed="8" stitchTiles="stitch" result="turbulence"/>
          	<feMorphology operator="dilate" radius="35 35" in="turbulence" result="morphology"/>
          	<feColorMatrix type="matrix" values="1 0 0 0 0
          0 1 0 0 0
          0 0 1 0 0
          0 0 0 10 0" in="morphology" result="colormatrix"/>
          	<feColorMatrix type="saturate" values="10" in="colormatrix" result="colormatrix1"/>
          	<feComposite in="colormatrix1" in2="SourceAlpha" operator="in" result="composite"/>
          </filter>
          <filter id="colorwaves" x="-20%" y="-20%" width="140%" height="140%" filterUnits="objectBoundingBox" primitiveUnits="userSpaceOnUse" colorInterpolationFilters="linearRGB">
          	<feTurbulence type="fractalNoise" baseFrequency="0.02 0.01" numOctaves="1" seed="2" stitchTiles="stitch" result="turbulence"/>
          	<feColorMatrix type="matrix" values="1 0 0 0 0
          0 1 0 0 0
          0 0 1 0 0
          0 0 0 0 1" in="turbulence" result="colormatrix"/>
          	<feColorMatrix type="saturate" values="4" in="colormatrix" result="colormatrix1"/>
          	<feGaussianBlur stdDeviation="3 3" in="componentTransfer" edgeMode="none" result="blur"/>
          	<feComponentTransfer in="composite" result="componentTransfer">
              		<feFuncR type="table" tableValues="1 0 -1"/>
          		<feFuncG type="table" tableValues="1 0 1"/>
          		<feFuncB type="table" tableValues="1 -1 1"/>
          		<feFuncA type="identity"/>
            	</feComponentTransfer>
          	<feComposite in="componentTransfer" in2="SourceAlpha" operator="in" result="composite"/>
          </filter>
          <filter id="colorstripes" x="-20%" y="-20%" width="140%" height="140%" filterUnits="objectBoundingBox" primitiveUnits="userSpaceOnUse" colorInterpolationFilters="linearRGB">
          	<feTurbulence type="turbulence" baseFrequency="0.03 0" numOctaves="10" seed="3" stitchTiles="stitch" result="turbulence"/>
          	<feColorMatrix type="saturate" values="20" in="morphology1" result="colormatrix"/>
          	<feFlood floodColor="#ffeb00" floodOpacity="1" result="flood"/>
          	<feComposite in="flood" in2="SourceGraphic" operator="in" result="composite"/>
          	<feComposite in="colormatrix" in2="SourceGraphic" operator="in" result="composite1"/>
          	<feMerge result="merge">
              		<feMergeNode in="composite" result="mergeNode"/>
          		<feMergeNode in="composite1" result="mergeNode1"/>
          		<feMergeNode in="composite1" result="mergeNode2"/>
          		<feMergeNode in="composite1" result="mergeNode3"/>
          		<feMergeNode in="composite1" result="mergeNode4"/>
          		<feMergeNode in="composite1" result="mergeNode5"/>
          		<feMergeNode in="composite1" result="mergeNode6"/>
            	</feMerge>
          </filter>
          <filter id="colorflame" x="-20%" y="-20%" width="140%" height="140%" filterUnits="objectBoundingBox" primitiveUnits="userSpaceOnUse" colorInterpolationFilters="linearRGB">
          	<feTurbulence type="fractalNoise" baseFrequency="0.035 0.008" numOctaves="1" seed="2" stitchTiles="stitch" result="turbulence"/>
          	<feTurbulence type="fractalNoise" baseFrequency="0.035 0.012" numOctaves="1" seed="1" stitchTiles="stitch" result="turbulence1"/>
          	<feMerge result="merge">
              		<feMergeNode in="turbulence1" result="mergeNode"/>
          		<feMergeNode in="turbulence" result="mergeNode1"/>
            	</feMerge>
          	<feColorMatrix type="saturate" values="10" in="merge" result="colormatrix"/>
          	<feColorMatrix type="matrix" values="1 0 0 0 0
          0 1 0 0 0
          0 0 1 0 0
          0 0 0 10 0" in="colormatrix" result="colormatrix1"/>
          	<feDisplacementMap in="colormatrix1" in2="colormatrix" scale="40" xChannelSelector="R" yChannelSelector="G" result="displacementMap"/>
          	<feComposite in="displacementMap" in2="SourceAlpha" operator="in" result="composite1"/>
          </filter>
          <filter id="dust" x="-20%" y="-20%" width="140%" height="140%" filterUnits="objectBoundingBox" primitiveUnits="userSpaceOnUse" colorInterpolationFilters="linearRGB">
          	<feTurbulence type="turbulence" baseFrequency="0.8 0.8" numOctaves="4" seed="4" stitchTiles="stitch" result="turbulence"/>
          	<feColorMatrix type="matrix" values="0 0 0 0 0
          0 0 0 0 0
          0 0 0 0 0
          0 0 0 -40 10" in="turbulence" result="colormatrix"/>
          	<feComposite in="colormatrix" in2="SourceAlpha" operator="in" result="composite"/>
          	<feTurbulence type="turbulence" baseFrequency="0.1 0.1" numOctaves="1" seed="2" stitchTiles="stitch" result="turbulence1"/>
          	<feDisplacementMap in="composite" in2="turbulence1" scale="20" xChannelSelector="R" yChannelSelector="B" result="displacementMap"/>
          </filter>
          <filter id="net" x="-20%" y="-20%" width="140%" height="140%" filterUnits="objectBoundingBox" primitiveUnits="userSpaceOnUse" colorInterpolationFilters="linearRGB">
          	<feTurbulence type="turbulence" baseFrequency="0.06 0.07" numOctaves="1" seed="4" stitchTiles="stitch" result="turbulence"/>
          	<feColorMatrix type="matrix" values="0 0 0 0 0
          0 0 0 0 0
          0 0 0 0 0
          0 0 0 -70 10" in="turbulence" result="colormatrix"/>
          	<feOffset dx="22" dy="4" in="colormatrix" result="offset"/>
          	<feComposite in="offset" in2="colormatrix" operator="xor" result="composite"/>
          	<feComposite in="composite" in2="SourceAlpha" operator="in" result="composite1"/>
          </filter>
          <filter id="waterdrops" x="-20%" y="-20%" width="140%" height="140%" filterUnits="objectBoundingBox" primitiveUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          	<feTurbulence type="turbulence" baseFrequency="0.05 0.05" numOctaves="1" seed="3" stitchTiles="stitch" result="turbulence"/>
          	<feComposite in="turbulence" in2="SourceGraphic" operator="in" result="composite"/>
          	<feColorMatrix type="matrix" values="1 0 0 0 0
          0 1 0 0 0
          0 0 1 0 0
          0 0 0 25 -2" in="composite" result="colormatrix"/>
          	<feComposite in="SourceGraphic" in2="colormatrix" operator="in" result="composite1"/>
          	<feGaussianBlur stdDeviation="3 3" in="composite1" edgeMode="none" result="blur"/>
          	<feSpecularLighting surfaceScale="2" specularConstant="1" specularExponent="20" lightingColor="#fffffd" in="blur" result="specularLighting">
              		<feDistantLight azimuth="-90" elevation="150"/>
            	</feSpecularLighting>
          	<feSpecularLighting surfaceScale="2" specularConstant="1" specularExponent="20" lightingColor="#cae1fe" in="blur" result="specularLighting1">
              		<feDistantLight azimuth="90" elevation="150"/>
            	</feSpecularLighting>
          	<feSpecularLighting surfaceScale="7" specularConstant="1" specularExponent="35" lightingColor="#fcfeff" in="blur" result="specularLighting2">
              		<fePointLight x="150" y="50" z="300"/>
            	</feSpecularLighting>
          	<feComposite in="specularLighting" in2="composite1" operator="in" result="composite2"/>
          	<feComposite in="specularLighting2" in2="composite1" operator="in" result="composite3"/>
          	<feComposite in="specularLighting1" in2="composite1" operator="in" result="composite4"/>
          	<feBlend mode="multiply" in="composite4" in2="SourceGraphic" result="blend"/>
          	<feBlend mode="color-dodge" in="composite2" in2="blend" result="blend1"/>
          	<feBlend mode="soft-light" in="composite3" in2="blend1" result="blend2"/>
          </filter>
          <filter id="watercolor" x="-20%" y="-20%" width="140%" height="140%" filterUnits="objectBoundingBox" primitiveUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
	<feTurbulence type="fractalNoise" baseFrequency="0.05 0.05" numOctaves="5" seed="1" stitchTiles="stitch" result="turbulence"/>
	<feDiffuseLighting surfaceScale="0.5" diffuseConstant="3.2" lightingColor="#ffffff" in="turbulence" result="diffuseLighting">
    		<feDistantLight azimuth="150" elevation="16"/>
  	</feDiffuseLighting>
	<feTurbulence type="fractalNoise" baseFrequency="0.011 0.004" numOctaves="2" seed="3" stitchTiles="noStitch" result="turbulence1"/>
	<feColorMatrix type="saturate" values="3" in="turbulence1" result="colormatrix"/>
	<feColorMatrix type="matrix" values="2 0 0 0 0
0 1.5 0 0 0
0 0 2 0 0
0 0 0 2 0" in="colormatrix" result="colormatrix1"/>
	<feBlend mode="multiply" in="diffuseLighting" in2="colormatrix1" result="blend"/>
	<feComposite in="blend" in2="SourceAlpha" operator="in" result="composite1"/>
</filter>
<filter id="paintdrops" x="-20%" y="-20%" width="140%" height="140%" filterUnits="objectBoundingBox" primitiveUnits="userSpaceOnUse" colorInterpolationFilters="linearRGB">
	<feTurbulence type="turbulence" baseFrequency="0.07 0.04" numOctaves="1" seed="4" stitchTiles="stitch" result="turbulence"/>
	<feColorMatrix type="matrix" values="1 0 0 0 0
0 1 0 0 0
0 0 1 0 0
0 0 0 25 -4" in="turbulence" result="colormatrix"/>
	<feGaussianBlur stdDeviation="2 7" in="colormatrix" edgeMode="none" result="blur"/>
	<feComposite in="blur" in2="SourceAlpha" operator="in" result="composite"/>
	<feSpecularLighting surfaceScale="25" specularConstant="1" specularExponent="20" lightingColor="#ffffff" in="composite" result="specularLighting">
    		<feDistantLight azimuth="45" elevation="150"/>
  	</feSpecularLighting>
	<feComposite in="specularLighting" in2="colormatrix" operator="in" result="composite1"/>
	<feComposite in="composite1" in2="SourceGraphic" operator="in" result="composite2"/>
	<feFlood floodColor="#003897" floodOpacity="1" result="flood"/>
	<feComposite in="flood" in2="colormatrix" operator="in" result="composite3"/>
	<feComposite in="composite3" in2="SourceGraphic" operator="in" result="composite4"/>
	<feMerge result="merge">
    		<feMergeNode in="composite4"/>
		<feMergeNode in="composite2"/>
  	</feMerge>
</filter>
<filter id="paper" x="-20%" y="-20%" width="140%" height="140%" filterUnits="objectBoundingBox" primitiveUnits="userSpaceOnUse" colorInterpolationFilters="linearRGB">
	<feTurbulence type="turbulence" baseFrequency="0.071 0.01" numOctaves="2" seed="15" stitchTiles="stitch" result="turbulence"/>
	<feColorMatrix type="matrix" values="1 0 0 0 0
0 1 0 0 0
0 0 1 0 0
0 0 0 50 -10" in="turbulence" result="colormatrix1"/>
	<feFlood floodColor="#000000" floodOpacity="1" result="flood"/>
	<feComposite in="flood" in2="colormatrix1" operator="in" result="composite"/>
	<feComposite in="composite" in2="SourceGraphic" operator="in" result="composite1"/>
</filter>
<filter id="zebra" x="-20%" y="-20%" width="140%" height="140%" filterUnits="objectBoundingBox" primitiveUnits="userSpaceOnUse" colorInterpolationFilters="linearRGB">
	<feTurbulence type="turbulence" baseFrequency="0.071 0.01" numOctaves="2" seed="15" stitchTiles="stitch" result="turbulence"/>
	<feColorMatrix type="matrix" values="1 0 0 0 0
0 1 0 0 0
0 0 1 0 0
0 0 0 50 -10" in="turbulence" result="colormatrix1"/>
	<feFlood floodColor="#000000" floodOpacity="1" result="flood"/>
	<feComposite in="flood" in2="colormatrix1" operator="in" result="composite"/>
	<feComposite in="composite" in2="SourceGraphic" operator="in" result="composite1"/>
</filter>
<filter id="smoke" x="-20%" y="-20%" width="140%" height="140%" filterUnits="objectBoundingBox" primitiveUnits="userSpaceOnUse" colorInterpolationFilters="linearRGB">
	<feTurbulence type="turbulence" baseFrequency="0.013 0.01" numOctaves="2" seed="1" stitchTiles="stitch" result="turbulence"/>
	<feFlood floodColor="#38252f" floodOpacity="1" result="flood"/>
	<feComposite in="flood" in2="turbulence" operator="in" result="composite1"/>
	<feComposite in="composite1" in2="SourceAlpha" operator="in" result="composite2"/>
</filter>
        </defs>
      </svg>
    );
  }
}

export default Filters;
