import React from 'react';
import ReactDOM from 'react-dom';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import App from './App';
import View from './View';
import reportWebVitals from './reportWebVitals';
import 'react-app-polyfill/stable';
import 'context-filter-polyfill';

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <Switch>
        <Route path="/view/:data" component={View} />
        <Route path="/">
          <App />
        </Route>
      </Switch>
    </Router>
  </React.StrictMode>,
  document.getElementById('root')
);

reportWebVitals();
