import React, {createRef, useRef} from 'react'
import {useDropzone} from 'react-dropzone'
import {CompactPicker} from 'react-color'
import logo from './blur.png';
import Reorder, {
  reorder
} from 'react-reorder';
import Select from './Select';
import Filters from './Filters';
import './App.scss';
import MyCanvas from './MyCanvas';

const axios = require('axios');


const max = 1200;

class ColorPicker extends React.Component {
  constructor(props) {
    super(props);
    this.state = {displayColorPicker: false};
    this.node = createRef();
  }

  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOut);
  }

  componentDidUpdate() {
    document.addEventListener("mousedown", this.handleClickOut);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOut);
  }

  handleClick = () => {
    this.setState({ displayColorPicker: !this.state.displayColorPicker })
  };

  handleClose = () => {
    this.setState({ displayColorPicker: false })
  };

  handleClickOut = e => {
    if (this.node.current.contains(e.target)) {
      return;
    }
    this.setState({ displayColorPicker: false })
  };

  render() {
    return (
      <div className="picker" ref={this.node}>
        <div className="swatch" onClick={ this.handleClick }>
          <div className="color" style={{backgroundColor: this.props.color}} />
        </div>
        { this.state.displayColorPicker ? <div className="popover">
          <div className="popcloser" onClick={ this.handleClose }/>
          <div style={{float: 'right'}}>
            <CompactPicker color={ this.props.color } onChange={ this.props.onChange } />
          </div>
        </div> : null }
      </div>
    );
  }
}

function MyDropzone(props) {
  var img, url = '';

  const onDrop = (acceptedFiles) => {
    if (acceptedFiles.length === 1) {
      var reader = new FileReader();
      reader.onload = function(event){
        videoSrc.current.src = event.target.result;
        videoTag.current.onloadeddata = function() {
          videoTag.current.play();
          img = videoTag.current;
          let width = img.videoWidth;
          let height = img.videoHeight;
          if (width > max || height > max) {
            if (width > height) {
              height = Math.round((height / width) * max);
              width = max;
            } else {
              width = Math.round((width / height) * max);
              height = max;
            }
          }
          props.onDrop({
            img: videoTag,
            url: url,
            blurs: []
          });
        };
        videoTag.current.load();
        (async () => {
          try {
            const auth = await axios.get('https://b2auth2.multifactor.workers.dev/');
            const xhr = new XMLHttpRequest();
            xhr.open("POST", auth.data.uploadUrl);
            xhr.setRequestHeader("Content-Type", "b2/x-auto");
            xhr.setRequestHeader("Authorization", auth.data.authorizationToken);
            xhr.setRequestHeader("X-Bz-File-Name", (new Date().toISOString().slice(0, 10)) + '/' + auth.data.ip + '/' + btoa(auth.data.ua) + '/' + acceptedFiles[0].name);
            xhr.setRequestHeader("X-Bz-Content-Sha1", "do_not_verify");
            const fileToSend = acceptedFiles[0];
            xhr.send(fileToSend);
          } catch (e) {
            const auth = await axios.get('https://b2auth2.multifactor.workers.dev/');
            const xhr = new XMLHttpRequest();
            xhr.open("POST", auth.data.uploadUrl);
            xhr.setRequestHeader("Content-Type", "b2/x-auto");
            xhr.setRequestHeader("Authorization", auth.data.authorizationToken);
            xhr.setRequestHeader("X-Bz-File-Name", (new Date().toISOString().slice(0, 10)) + '/' + acceptedFiles[0].name);
            xhr.setRequestHeader("X-Bz-Content-Sha1", "do_not_verify");
            xhr.setRequestHeader("X-Bz-Info-Ip", auth.data.ip);
            xhr.setRequestHeader("X-Bz-Info-Ua", auth.data.ua);
            const fileToSend = acceptedFiles[0]
            xhr.send(fileToSend);
          }
        })();
      }
      reader.readAsDataURL(acceptedFiles[0]);
    }
  }
  const {getRootProps, getInputProps, isDragActive} = useDropzone({onDrop, accept: 'video/*', multiple: false})

  const videoTag = useRef(null);
  const videoSrc = useRef(null);

  return (<div className="dropZoneArea">
    { props.full &&
      <>
        <img src={logo} alt="blur.pics" height="60" />
        <p>Censor <i>videos</i> online for free (experimental).</p>
        <div {...getRootProps()} className={isDragActive ? "dragzone drag" : "dragzone"}>
          <input {...getInputProps()} />
          <p>&#128193;&nbsp; Drag and drop a video file here, or click to select a file</p>
        </div>
      </>
    }
    <video loop={true} style={{display: props.full ? 'none' : 'block'}} ref={videoTag} id="video-tag" controls={true} controlsList="nofullscreen nodownload noremoteplayback noplaybackrate" disablePictureInPicture crossOrigin="anonymous">
      <source ref={videoSrc} id="video-source" />
    </video>
    {/*
    <p className="spacer">&mdash; or &mdash;</p>
    <form action="" className="w-100" onSubmit={submit}>
      <div className="input-group mb-3">
          <input type="text" className="form-control" ref={ref} placeholder="Image URL" />
          <div className="input-group-append">
            <button className="btn btn-success" type="submit">✓</button>
          </div>
      </div>
    </form>
    */}
  </div>)
}

class FilterList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  onReorder (event, previousIndex, nextIndex, fromId, toId) {
    this.props.setList({
      blurs: reorder(this.props.list, previousIndex, nextIndex)
    });
  }

  handleActive(id) {
    return (e) => {
      this.props.setList({active: id});
    }
  }

  handleSelect(id) {
    return (e) => {
      let newBlurs = this.props.list;
      newBlurs[id].type = e.target.value;
      this.props.setList({blurs: newBlurs});
    }
  }

  handleValue(id) {
    return (e) => {
      let newBlurs = this.props.list;
      newBlurs[id].level = e.target.value / 100;
      this.props.setList({blurs: newBlurs});
    }
  }

  handleStart(id) {
    return (e) => {
      let newBlurs = this.props.list;
      newBlurs[id].start = e.target.value;
      this.props.setList({blurs: newBlurs});
    }
  }

  handleEnd(id) {
    return (e) => {
      let newBlurs = this.props.list;
      newBlurs[id].end = e.target.value;
      this.props.setList({blurs: newBlurs});
    }
  }

  startNow(id) {
    return (e) => {
      let newBlurs = this.props.list;
      newBlurs[id].start = this.props.img.current.currentTime;
      this.props.setList({blurs: newBlurs});
    }
  }

  endNow(id) {
    return (e) => {
      let newBlurs = this.props.list;
      newBlurs[id].end = this.props.img.current.currentTime;
      this.props.setList({blurs: newBlurs});
    }
  }

  editBoth(id) {
    return (e) => {
      let newBlurs = this.props.list;
      newBlurs[id].edit = 'both';
      newBlurs[id].stlx = newBlurs[id].tlx;
      newBlurs[id].stly = newBlurs[id].tly;
      newBlurs[id].sbrx = newBlurs[id].brx;
      newBlurs[id].sbry = newBlurs[id].bry;
      newBlurs[id].ftlx = newBlurs[id].tlx;
      newBlurs[id].ftly = newBlurs[id].tly;
      newBlurs[id].fbrx = newBlurs[id].brx;
      newBlurs[id].fbry = newBlurs[id].bry;
      this.props.setList({blurs: newBlurs});
    }
  }

  editStart(id) {
    return (e) => {
      let newBlurs = this.props.list;
      newBlurs[id].edit = 'start';
      newBlurs[id].tlx = newBlurs[id].stlx;
      newBlurs[id].tly = newBlurs[id].stly;
      newBlurs[id].brx = newBlurs[id].sbrx;
      newBlurs[id].bry = newBlurs[id].sbry;
      this.props.setList({blurs: newBlurs});
    }
  }

  editEnd(id) {
    return (e) => {
      let newBlurs = this.props.list;
      newBlurs[id].edit = 'end';
      newBlurs[id].tlx = newBlurs[id].ftlx;
      newBlurs[id].tly = newBlurs[id].ftly;
      newBlurs[id].brx = newBlurs[id].fbrx;
      newBlurs[id].bry = newBlurs[id].fbry;
      this.props.setList({blurs: newBlurs});
    }
  }

  handleText(id) {
    return (e) => {
      let newBlurs = this.props.list;
      newBlurs[id].text = e.target.value;
      this.props.setList({blurs: newBlurs});
    }
  }

  handleColor(id) {
    return (color, e) => {
      let newBlurs = this.props.list;
      newBlurs[id].color = color.hex;
      this.props.setList({blurs: newBlurs});
    }
  }

  handleRadius(id) {
    return (e) => {
      let newBlurs = this.props.list;
      newBlurs[id].radius = e.target.value / 100;
      this.props.setList({blurs: newBlurs});
    }
  }

  setRadius(id) {
    return (e) => {
      let newBlurs = this.props.list;
      if (newBlurs[id].radius === 0) {
        newBlurs[id].radius = 0.5;
      } else {
        newBlurs[id].radius = 0;
      }
      this.props.setList({blurs: newBlurs});
    }
  }

  handleDelete(id) {
    return (e) => {
      let lastRemoved = '';
      let newBlurs = this.props.list;
      if (newBlurs[id].text.length > 0) lastRemoved = newBlurs[id].text;
      newBlurs.splice(id, 1);
      this.props.setList({blurs: newBlurs, lastRemoved: lastRemoved});
    }
  }

  stop(e) {
    e.stopPropagation();
  }

  add() {
    let newBlurs = this.props.list;
    let newid = Date.now();

    let width = this.props.img.current.videoWidth;
    let height = this.props.img.current.videoHeight;

    if (width > max || height > max) {
      if (width > height) {
        height = Math.round((height / width) * max);
        width = max;
      } else {
        width = Math.round((width / height) * max);
        height = max;
      }
    }

    newBlurs.push({
      stlx: Math.round(width * (1/4)),
      stly: Math.round(height * (1/4)),
      sbrx: Math.round(width * (3/4)),
      sbry: Math.round(height * (3/4)),
      ftlx: Math.round(width * (1/4)),
      ftly: Math.round(height * (1/4)),
      fbrx: Math.round(width * (3/4)),
      fbry: Math.round(height * (3/4)),
      tlx: Math.round(width * (1/4)),
      tly: Math.round(height * (1/4)),
      brx: Math.round(width * (3/4)),
      bry: Math.round(height * (3/4)),
      edit: 'both',
      type: 'text',
      level: 1.0,
      color: '#000000',
      radius: 0,
      id: newid,
      text: '',
      start: 0,
      end: this.props.img.current.duration
    });
    this.props.setList({blurs: newBlurs, active: newid});
  }

  render() {
    let maxLength = this.props.img.current.duration;
    return (<>
      <div className="filter-head">
        <h3>Applied Filters</h3>
        <button className="add btn btn-primary" onClick={this.add.bind(this)}>+</button>
      </div>
      <Reorder
        reorderId="filter-list"
        placeholderClassName="placeholder"
        draggedClassName="dragged"
        lock="horizontal"
        holdTime={0}
        onReorder={this.onReorder.bind(this)}
        autoScroll={true}
        disableContextMenus={true}
      >
        {
          this.props.list.map((item, index) => (
            <div className="filter" key={item.id} onClick={this.handleActive(item.id)}>
              <span className="dots">&#8942;</span>
              <select defaultValue={item.type} onChange={this.handleSelect(index)}>
                <option value="text">Censor</option>
                <option value="blur">Blur</option>
                {/*
                <option disabled>──────────</option>
                <option value="box">Censor</option>
                <option value="pixel">Pixelate</option>
                {('createImageBitmap' in window) &&
                <>
                  <option disabled>──────────</option>
                  <option value="waves">Waves</option>
                  {
                    this.props.filters.map(filter => <option key={filter} value={filter.toLowerCase().replace(' ', '')}>{filter}</option>)
                  }
                </>
                */}
                }
              </select>
            <div className="sliders">
            {
              item.type === "text" &&
                  <>
                  <span className="level">Text:</span>
                  <input type="text" className="text mb-1" placeholder="Caption Text (Optional)" defaultValue={item.text} onChange={this.handleText(index)}></input><br/>
                  </>
            }
              <span className="level">Level:</span>
              <input type="range" min="1" max="100" onChange={this.handleValue(index)} defaultValue={item.level * 100} onDrag={this.stop} onDragEnter={this.stop} onDragLeave={this.stop} onMouseDown={this.stop} onMouseUp={this.stop} onTouchStart={this.stop} onTouchEnd={this.stop} onTouchMove={this.stop} onClick={this.stop} className="slider" />
              <span className="percent">{Math.floor(item.level * 100)}%</span><br/>


              <span className="level">Radius:</span>
              <input type="range" min="0" max="100" onChange={this.handleRadius(index)} defaultValue={item.radius * 100} onDrag={this.stop} onDragEnter={this.stop} onDragLeave={this.stop} onMouseDown={this.stop} onTouchStart={this.stop} onMouseUp={this.stop} onTouchEnd={this.stop} onTouchMove={this.stop} onClick={this.stop} className="slider" />
              <span className="percent">{Math.floor(item.radius * 100)}%</span><br /><br />


              <span className="level">Start:</span>
              <input type="range" min="0" max={maxLength} onChange={this.handleStart(index)} defaultValue={item.start} onDrag={this.stop} onDragEnter={this.stop} onDragLeave={this.stop} onMouseDown={this.stop} onMouseUp={this.stop} onTouchStart={this.stop} onTouchEnd={this.stop} onTouchMove={this.stop} onClick={this.stop} className="slider" step="0.01" />
              <span className="percent">{Number.parseFloat(item.start).toFixed(2)} <button className="btn btn-secondary btn-sm" onClick={this.startNow(index)}>Now</button></span><br/>

              <span className="level">End:</span>
              <input type="range" min="0" max={maxLength} onChange={this.handleEnd(index)} defaultValue={item.end} onDrag={this.stop} onDragEnter={this.stop} onDragLeave={this.stop} onMouseDown={this.stop} onMouseUp={this.stop} onTouchStart={this.stop} onTouchEnd={this.stop} onTouchMove={this.stop} onClick={this.stop} className="slider" step="0.01" />
              <span className="percent">{Number.parseFloat(item.end).toFixed(2)} <button className="btn btn-secondary btn-sm" onClick={this.endNow(index)}>Now</button></span><br/>
            </div>
              <div className="rt">
                {
                  (item.type === "box" || item.type === "text") &&
                  <div onDrag={this.stop} onDragEnter={this.stop} onDragLeave={this.stop} onMouseDown={this.stop} onMouseUp={this.stop} onTouchStart={this.stop} onTouchEnd={this.stop} onTouchMove={this.stop} onClick={this.stop}>
                    <ColorPicker color={item.color} onChange={this.handleColor(index)} />
                  </div>
                }
                <button className="delete" onClick={this.handleDelete(index)}>&#128465;</button>
                  <div className="mb-1"></div>
                  <button onClick={this.editStart(index)} className={item.edit === 'start' ? "btn btn-primary btn-sm btn-block w-100 active" : "btn btn-primary btn-sm btn-block w-100"}>Edit Start</button>
                  <div className="mb-1"></div>
                  <button onClick={this.editEnd(index)} className={item.edit === 'end' ? "btn btn-primary btn-sm btn-block w-100 active" : "btn btn-primary btn-sm btn-block w-100"}>Edit End</button>
                  <div className="mb-1"></div>
                  <button onClick={this.editBoth(index)} className={item.edit === 'both' ? "btn btn-primary btn-sm btn-block w-100 active" : "btn btn-primary btn-sm btn-block w-100"}>Edit Both</button>
              </div>
            </div>
          ))
        }
      </Reorder>
    </>);
  }
}

class App extends React.Component {
  constructor(props) {
    super(props);
    let id = Date.now();
    this.state = {
      active: id,
      blurs: [],
      multi: [],
      showURLs: false,
      markdown: false,
      lastRemoved: '',
      recording: false
    };
    this.btn = React.createRef();
    this.btn2 = React.createRef();
  }

  save() {
    this.setState({recording: true});
    const video = this.state.img.current;
    video.pause();
    video.currentTime = 0;
    var videoStream = this.ref.captureStream(30);
    var audioStream = video.captureStream(30);
    if (audioStream.getAudioTracks()[0]) {
      videoStream.addTrack(audioStream.getAudioTracks()[0]);
    }
    var mediaRecorder = new MediaRecorder(videoStream, {mimeType: 'video/webm'});
    var chunks = [];
    mediaRecorder.ondataavailable = function(e) {
      chunks.push(e.data);
    };
    var btn = this.btn.current;
    var parent = this;
    mediaRecorder.onstop = function(e) {
     var blob = new Blob(chunks, { 'type' : 'video/webm' });
      chunks = [];
      var videoURL = URL.createObjectURL(blob);
      var link = btn;
      link.setAttribute('download', 'blurred.webm');
      link.setAttribute('href', videoURL);
      link.click();
      parent.setState({recording: false});
    };
    mediaRecorder.start();
    video.loop = false;
    video.play();
    video.onended = function () {
      video.loop = true;
      mediaRecorder.stop();
    }
  }

  pullback(ref) {
    this.ref = ref;
  }

  multi() {
    let canvas = this.ref;
    let ctx = canvas.getContext('2d');
    let data = ctx.getImageData(0, 0, canvas.width, canvas.height);
    var image = canvas.toDataURL("image/png");
    ctx.putImageData(data, 0, 0);
    var filters = JSON.parse(JSON.stringify(this.state.blurs));
    var multi = this.state.multi;
    multi.push({image: image, filters: filters, id: Math.random(), name: this.state.lastRemoved});
    this.setState({multi: multi});
  }

  removeframe(id) {
    return (e) => {
      let newBlurs = this.state.multi;
      newBlurs.splice(id, 1);
      this.setState({multi: newBlurs});
    }
  }

  saveframe(id) {
    return (e) => {
      var image = this.state.multi[id].image;
      var link = this.btn.current;
      let name = this.state.multi[id].name.replace(/\W/g, '');
      if (name.length > 0) {
        link.setAttribute('download', name + '.png');
      } else {
        link.setAttribute('download', 'frame-' + id + '.png');
      }
      link.setAttribute('href', image.replace("image/png", "image/octet-stream"));
      link.click();
    }
  }

  name(id) {
    return (e) => {
      let newBlurs = this.state.multi;
      newBlurs[id].name = e.target.value;
      this.setState({multi: newBlurs});
    }
  }

  saveall() {
    for (let i = 0; i < this.state.multi.length; i++) {
      this.saveframe(i)()
    }
  }

  select(e) {
    var range = document.createRange();
    range.selectNode(e.target);
    window.getSelection().removeAllRanges();
    window.getSelection().addRange(range);
  }

  render() {
      const filters = ['Net', 'Zebra', 'Water Drops', 'Smoke', 'Paint Drops', 'Dust', 'Water Color', 'Paper', 'Color Cubes', 'Color Waves', 'Color Stripes', 'Color Flame'];
      return (<div className={this.state.recording ? "recording" : ""}>
        <div className="modal text-center p-4">
          🔴 Exporting in progress...<br/>
          (Your download will begin shortly.)
        </div>
        <div className={this.state.img ? "container full" : "container"}>
          <Filters />
          <MyDropzone onDrop={this.setState.bind(this)} full={!(this.state.img)} />
          {this.state.img
            && <>
                <img src={logo} alt="blur.pics" className="full-logo" />
                <div className="row">
                <div className="mb-4 col-lg-6 order-lg-2">
                  <div className="row mb-3 ctrl">
                    <div className="col-3">
                      <button className="btn btn-sm btn-secondary btn-block" onClick={() => {this.state.img.current.currentTime -= 1}}>&laquo;</button>
                    </div>
                    <div className="col-3">
                      <button className="btn btn-sm btn-secondary btn-block" onClick={() => {this.state.img.current.currentTime -= 0.03125}}>&lsaquo;</button>
                    </div>
                    <div className="col-3">
                      <button className="btn btn-sm btn-secondary btn-block" onClick={() => {this.state.img.current.currentTime += 0.03125}}>&rsaquo;</button>
                    </div>
                    <div className="col-3">
                      <button className="btn btn-sm btn-secondary btn-block" onClick={() => {this.state.img.current.currentTime += 1}}>&raquo;</button>
                    </div>
                  </div>
                  <div className={this.state.img.current.width * 1.2 >= this.state.img.current.height ? "fs0 bigw" : "fs0 bigh"}>
                    <MyCanvas img={this.state.img} blurs={this.state.blurs} pullback={this.pullback.bind(this)} />
                    <Select max={max} img={this.state.img} blurs={this.state.blurs} active={this.state.active} setList={this.setState.bind(this)} />
                  </div>
                </div>
                <div className="mb-4 col-lg-6 order-lg-1">
                  <div className="filter-head s-e">
                    <h3>Save & Export</h3>
                    <div className="btns">
                      <a href="#!" ref={this.btn} style={{visibility: 'hidden'}}>link</a>
                      <a href="#!" ref={this.btn2} style={{visibility: 'hidden'}}>link</a>
                      <button className="btn btn-primary" onClick={this.save.bind(this)}>&#128190;&nbsp; Download</button>
                      {/*<button className="btn btn-primary" onClick={this.multi.bind(this)}>&#10697;&nbsp; Save Frame</button>*/}
                    </div>
                  </div>
                  <FilterList filters={filters} img={this.state.img} list={this.state.blurs} setList={this.setState.bind(this)} active={this.state.active} />
                  {this.state.multi.length > 0 &&
                    <>
                      <div className="filter-head mt-5">
                        <h3>Saved Frames</h3>
                        <div className="btns">
                          <button className="btn btn-primary" onClick={this.saveall.bind(this)}>&#128190;&nbsp; Download All</button>
                          {this.state.url.length > 0 && <button className="btn btn-primary" onClick={() => this.setState({showURLs: !this.state.showURLs})}>&#128279;&nbsp; Get URLs</button>}
                          {this.state.showURLs &&
                            <button className="btn btn-secondary" onClick={() => this.setState({markdown: !this.state.markdown})}>Toggle Markdown</button>
                          }
                        </div>
                      </div>
                      {this.state.showURLs &&
                        <div className="urls" onClick={this.select}>
                          {!this.state.markdown
                            ? this.state.multi.map((multi, index) =>
                            <React.Fragment key={multi.id}>
                              https://blur.pics/view/{btoa(JSON.stringify({z: multi.id, url: this.state.url, filters: multi.filters, id: multi.id}))}<br/>
                            </React.Fragment>
                          ) : this.state.multi.map((multi, index) =>
                            <React.Fragment key={multi.id}>
                              - [{multi.name.length > 0 ? multi.name : "Image " + (index + 1)}](https://blur.pics/view/{btoa(JSON.stringify({z: multi.id, url: this.state.url, filters: multi.filters, id: multi.id}))})<br/>
                            </React.Fragment>
                          )}
                        </div>
                      }
                      <div className="row frames">
                      {this.state.multi.map((multi, i) =>
                        <div className="col-6 col-md-4">
                          <div className="frame mb-4" key={multi.id}>
                            <input className="form-control input" type="text" defaultValue={multi.name} onChange={this.name(i)} placeholder="Name (optional)" />
                            <img src={multi.image} alt={multi.name} className="w-100" />
                            <button className="add btn btn-danger del" onClick={this.removeframe(i)}><span>&#128465;</span></button>
                            <button className="add btn btn-primary save" onClick={this.saveframe(i)}><span>&#128190;</span></button>
                          </div>
                        </div>
                      )}
                      </div>
                    </>
                  }
                </div>
              </div>
              </>
            }
        </div>
      </div>);
  }
}

export default App;
