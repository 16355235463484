import React from 'react'
import MyCanvas from './MyCanvas'
import Filters from './Filters'

class View extends React.Component {
  constructor(props) {
    super(props);
    this.state = JSON.parse(atob(this.props.match.params.data));
  }

  componentDidMount() {
    var img = new Image();
    img.onload = function() {
      this.setState({img: img});
    }.bind(this);
    img.crossOrigin = "Anonymous";
    img.src = this.state.url;
  }

  render() {
    return (<div className="view-pre">
      <Filters />
      {this.state.img &&
        <MyCanvas img={this.state.img} blurs={this.state.filters} pullback={() => {}} />
      }
    </div>);
  }
}

export default View;
